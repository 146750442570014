import React from 'react';
import styled, { css } from 'styled-components';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import orbits3 from '../../assets/images/orbits3.svg';
import {
  GradientTitle,
  TextMedium,
} from '../../components/atoms/Typography/Typography';
import {
  forScreenAtMost800pxWide,
  forScreenAtMost1000pxWide,
} from '../../styles/mediaQueries';

const GrantsSection = (): JSX.Element => (
  <Wrapper>
    <MainContentColumnWrapper>
      <InnerWrapper>
        <Background>
          <Header>Relacje Inwestorskie</Header>
        </Background>
      </InnerWrapper>
      <MainWrapper>
        <ContentWrapper>
          <ContentText>
            <strong>NATURALANTIBODY SPÓŁKA AKCYJNA</strong> w Szczecinie. KRS
            0000887528. SĄD REJONOWY SZCZECIN-CENTRUM W SZCZECINIE, XIII WYDZIAŁ
            GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO, wpis do rejestru: 8 marca
            2021 r.
            {'\n\n'}
          </ContentText>

          <ContentText>
            Zarząd NATURALANTIBODY S.A. z siedzibą: Al. Piastów 22, 71-064
            Szczecin, działając na podstawie art. 399 § 1 k.s.h. w zw. z §14
            ust. 2 Statutu, zwołuje Zwyczajne Walne Zgromadzenie Akcjonariuszy
            Spółki na dzień 27.06.2023r., godz. 14:30. Zwyczajne Walne
            Zgromadzenie odbędzie się w stosownie do postanowień §14 ust. 2 w
            Warszawie, przy ul. Kruczej 5/11 lok. 42, z następującym porządkiem
            obrad:
            {'\n'}
            <ol>
              <li>Otwarcie Zwyczajnego Walnego Zgromadzenia</li>
              <li>Wybór Przewodniczącego Zgromadzenia</li>
              <li>
                Stwierdzenie prawidłowości zwołania Zgromadzenia i zdolności do
                podejmowania uchwał oraz przyjęcie porządku obrad
              </li>
              <li>
                Podjęcie uchwały w sprawach:
                <ul>
                  <li>
                    rozpatrzenia i zatwierdzenia sprawozdania finansowego Spółki
                    za 2022 rok;
                  </li>
                  <li>
                    rozpatrzenia i zatwierdzenia sprawozdania Zarządu z
                    działalności Spółki za 2022 rok;
                  </li>
                  <li>pokrycia straty za 2022 rok;</li>
                  <li>
                    udzielenia absolutorium Członkom Zarządu z wykonania
                    obowiązków za 2022 rok;
                  </li>
                  <li>
                    udzielenia absolutorium Członkom Rady Nadzorczej z wykonania
                    obowiązków za 2022 rok.
                  </li>
                </ul>
              </li>
              <li>Zamknięcie Zgromadzenia.</li>
            </ol>
          </ContentText>
        </ContentWrapper>
      </MainWrapper>
    </MainContentColumnWrapper>
  </Wrapper>
);

const MainWrapper = styled.div`
  display: flex;
  ${forScreenAtMost1000pxWide(css`
    flex-direction: column;
  `)}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const Wrapper = styled.div`
  ${forScreenAtMost800pxWide(css`
    margin-bottom: 300px;
  `)}
  ${forScreenAtMost1000pxWide(css`
    overflow: hidden;
  `)}
  margin-bottom: 200px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 240px 0 0 0;

  ${forScreenAtMost1000pxWide(css`
    flex-direction: column;
    gap: 0px;
    padding: 134px 0 0 0;
  `)}
`;

const Background = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -400px;
    left: 690px;
    width: 916px;
    height: 916px;
    background: url('${orbits3}');
    background-size: cover;
  }

  ${forScreenAtMost1000pxWide(css`
    :before {
      top: -150px;
      left: 460px;
      width: 453px;
      height: 453px;
    }
  `)}
`;

const Header = styled(GradientTitle).attrs({ $gradient: 'primary' })`
  max-width: 358px;
  margin-bottom: 75px;
`;

const ContentText = styled(TextMedium).attrs({
  $color: 'delicateAccented',
})`
  white-space: pre-wrap;
`;

export default GrantsSection;
