import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import AnnouncementsSection from '../pages-components/announcements/AnnouncementsSection';

const IndexPage = (): JSX.Element => {
  return (
    <Layout
      backgroundColor="secondary"
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Announcements"
    >
      <AnnouncementsSection />
    </Layout>
  );
};

export default IndexPage;
